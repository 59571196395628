import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { IconCheckmark, InfoModal, theme } from '@konecorp/ui-library';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Link, Typography } from '@material-ui/core';
import {
  API_TYPE,
  fetchSubcontractors,
  fetchEmployeeFullName,
  get,
  put,
} from '../../helpers/fetch';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ActivityDifferentiator,
  ExtendedInstallation,
  Installation,
  InstallationStatus,
  isAssignment,
} from '../../schemas';
import { InstallationTask } from '../InstallationTask';
import Context from '../../context';
import SubHeader from '../../components/SubHeader';

import { useUpdateInstallationListsInContext } from '../../hooks/useUpdateInstallationListsInContext';
import { useGetToken } from '../../hooks/useGetToken';
import TeamList from '../../components/TeamList';
import { getByObjId } from '../../helpers/installationTasks';

export type SupervisorNewInstallationProps = {
  installation: Installation | ExtendedInstallation;
  networkNumber: string;
};
const useStyles = makeStyles((t: Theme) =>
  createStyles({
    content: {
      marginLeft: t.spacing(2),
      marginRight: t.spacing(2),
      paddingTop: t.spacing(3),
      height: '100%',
    },
    greenHeadline: {
      backgroundColor: t.palette.success.light,
      padding: t.spacing(1),
    },
    blueHeadline: {
      backgroundColor: t.palette.info.light,
      padding: t.spacing(1),
    },
    grayHeadline: {
      backgroundColor: '#E0E0E0',
      padding: t.spacing(1),
    },
    assigmentInfo: {
      paddingLeft: t.spacing(3),
      paddingBottom: t.spacing(1),
      paddingTop: t.spacing(1),
    },
    extraInfo: {
      marginTop: t.spacing(2),
      '& > div': {
        paddingLeft: t.spacing(3),
        paddingTop: t.spacing(2),
      },
    },
    buttonRow: {
      paddingLeft: 0,
    },
    upperCase: {
      textTransform: 'uppercase',
    },
    closeDialogIcon: {
      width: 30,
      height: 30,
      position: 'absolute',
      zIndex: 1,
      right: theme.spacing(1.5),
      top: theme.spacing(1.5),
    },
  })
);
const SupervisorNewInstallation = (
  props: PropsWithChildren<SupervisorNewInstallationProps>
): JSX.Element => {
  const { installation, networkNumber } = props;
  const { updateIsLoading } = useContext(Context);
  const history = useHistory();
  const [getTokenFunction] = useGetToken();
  const { t } = useTranslation();
  const [updateInstallationListsInContext] = useUpdateInstallationListsInContext();
  const [installationTask, setInstallationTask] = useState<InstallationTask | null>(null);
  const [confirmationDialogOpen, setOpenConfimationDialog] = useState<boolean>(false);
  const [installerNames, setInstallerNames] = useState<string[]>([]);
  const handleCloseConfirmationDialog = async () => {
    try {
      updateIsLoading(true);
      await updateInstallationListsInContext();
      updateIsLoading(false);
      history.push('/tobestarted');
    } catch (e) {
      updateIsLoading(false);
      console.error('error when fetching installation lists', e);
    }
  };
  const handleStartButtonClick = async (): Promise<void> => {
    try {
      updateIsLoading(true);
      const accessToken = await getTokenFunction();

      await put(
        `v1/installations/${networkNumber}/status`,
        accessToken,
        API_TYPE.APPLICATION,
        {
          status: InstallationStatus.FOR_INSTALLER_ACCEPTANCE,
        }
      );
      const installersNames = await getInstallerNames();
      setInstallerNames(installersNames);
      setOpenConfimationDialog(true);
    } catch (error) {
      console.error('error while updating installation status', error);
    } finally {
      updateIsLoading(false);
    }
  };

  const fetchInstallationTaskData = async (): Promise<InstallationTask | null> => {
    try {
      const accessToken = await getTokenFunction();

      const fetchedInstallationTaskIds = await get(
        `v1/installationtasks?typFlNetwork=${networkNumber}`,
        accessToken
      );
      if (fetchedInstallationTaskIds[0]) {
        return await getByObjId(fetchedInstallationTaskIds[0], accessToken);
      }
    } catch (error) {
      console.error('error while fetching installation task data', error);
    }
    return null;
  };

  const getInstallerNames = async () => {
    const accessToken = await getTokenFunction();
    const subcontractors = await fetchSubcontractors(networkNumber, accessToken);
    const subcontractorInstallers = subcontractors.filter(
      (subcontractor) =>
        subcontractor.activityDifferentiator === ActivityDifferentiator.INST
    );
    const latestInstallers = await get(
      `v1/installations/${networkNumber}/?getBy=networkNumber`,
      accessToken
    );
    const koneInstaller = latestInstallers.assignees.filter(
      (worker: { activityDifferentiator: ActivityDifferentiator }) =>
        worker.activityDifferentiator === ActivityDifferentiator.INST &&
        !('subcontractor' in worker)
    );
    const installers = [...koneInstaller, ...subcontractorInstallers];
    const installerNamePromises = installers.map((worker) =>
      isAssignment(worker)
        ? fetchEmployeeFullName(worker.koneResourcePersonalNumber, accessToken)
        : Promise.resolve(worker.subcontractor.name)
    );
    return await Promise.all(installerNamePromises);
  };

  useEffect(() => {
    (async () => {
      updateIsLoading(true);
      try {
        const installationTask = await fetchInstallationTaskData();
        setInstallationTask(installationTask);
      } catch (err) {
        console.error(err);
      } finally {
        updateIsLoading(false);
      }
    })();
  }, []);

  const classes = useStyles(theme);

  const renderCustomerInfo = (networkNumber: string): JSX.Element => {
    const {
      customerName1 = '',
      street = ' ',
      houseNumber = '',
      city = '',
      cityPostalCode = '',
      countryKey = '',
      partnerFunction = '',
    } = installation.customer ?? {};
    if (partnerFunction !== 'WE') {
      return <></>;
    }
    return (
      <>
        <Grid container direction="row" spacing={2}>
          <Grid container item>
            <Grid item xs={6} md={2}>
              <Typography className={classes.upperCase}>
                {t('deviationsTable.network')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={2}>
              <Typography>{networkNumber}</Typography>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={6} md={2}>
              <Typography className={classes.upperCase}>
                {t('supervisorNewInstallation.customer')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={2}>
              <Typography>{customerName1}</Typography>
              <Typography>
                {street} {houseNumber}
              </Typography>
              <Typography>
                {cityPostalCode} {city}, {countryKey}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderExtraInfo = (): JSX.Element => {
    const openTacoLink = installationTask
      ? `${process.env.REACT_APP_TACO_PLAN_STUB}/${installationTask?.attributeValues.tacoConfigurationId}`
      : process.env.REACT_APP_TACO_PLAN_STUB;

    const eligibleStatusToStart =
      installation.status === null ||
      installation.status === InstallationStatus.TO_BE_STARTED;

    return (
      <>
        <Grid item className={classes.extraInfo}>
          <Typography>{t('supervisorNewInstallation.beforeStarting')}</Typography>
          <Grid container>
            <Grid item xs={2}>
              <IconCheckmark style={{ fontSize: '2rem' }} />
            </Grid>
            <Grid item xs={10}>
              <Typography> {t('supervisorNewInstallation.siteOK')} </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2}>
              <IconCheckmark style={{ fontSize: '2rem' }} />
            </Grid>
            <Grid item xs={10}>
              <Typography display="inline">
                {' '}
                {t('supervisorNewInstallation.techniciansAllocated')}{' '}
              </Typography>
              <Typography display="inline" color="textSecondary">
                {' '}
                {t('supervisorNewInstallation.irmaDefault')}{' '}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2}>
              <IconCheckmark style={{ fontSize: '2rem' }} />
            </Grid>
            <Grid item xs={6}>
              <Typography display="inline">
                {' '}
                {t('supervisorNewInstallation.installationPlanAgreed')}{' '}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography display="inline">
                <Link href={openTacoLink}>
                  {t('supervisorNewInstallation.openInTACO')}
                </Link>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.buttonRow}
          >
            {eligibleStatusToStart ? (
              <Grid>
                <Button
                  data-testid="start-button"
                  id="start-button"
                  variant="contained"
                  color="primary"
                  onClick={handleStartButtonClick}
                >
                  <Typography>
                    {' '}
                    {t('supervisorNewInstallation.sendInstallationForAcceptance')}{' '}
                  </Typography>
                </Button>
                <InfoModal
                  closeButtonText={t('supervisorNewInstallation.OK')}
                  message={t('supervisorNewInstallation.markedMessage', {
                    installerNames: installerNames.join(', '),
                  })}
                  open={confirmationDialogOpen}
                  onClose={handleCloseConfirmationDialog}
                  isCenteredMessage
                />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <>
      <SubHeader
        title={t('myInstallation.toBeStartedInstallations')}
        handleGoBackClick={() => {
          history.push('/tobestarted');
        }}
      />
      <div className={classes.content}>
        <Grid container spacing={2}>
          {renderCustomerInfo(networkNumber)}
          <TeamList installation={installation} networkNumber={networkNumber} />
          {renderExtraInfo()}
        </Grid>
      </div>
    </>
  );
};
export default SupervisorNewInstallation;
