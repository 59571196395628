import { useContext } from 'react';
import Context from '../context/index';
import { ActivityDifferentiator, InstallationStatus } from '../schemas';
import { useGetCurrentUserRole } from './useGetCurrentUserRole';
import { compareStatus, CompareStatusResult } from '../helpers/getInstallationLists';

export const useIfReadOnly = (): [boolean] => {
  const { installationData } = useContext(Context);
  const [userRole] = useGetCurrentUserRole();

  if (!installationData?.status || !userRole) return [false];

  switch (userRole) {
    case ActivityDifferentiator.INST: {
      const isReadOnly =
        compareStatus(installationData.status, InstallationStatus.INSTALLER_ACCEPTED) ===
        CompareStatusResult.AFTER;

      if (installationData.status === InstallationStatus.INSTALLER_ACCEPTED)
        return [false];
      else if (isReadOnly) return [true];

      throw new Error('Error in useIfReadOnly hook while checking for INST role');
    }

    case ActivityDifferentiator.CMSN: {
      const isReadOnly =
        compareStatus(installationData.status, InstallationStatus.TESTER_ACCEPTED) ===
        CompareStatusResult.AFTER;

      if (installationData.status === InstallationStatus.TESTER_ACCEPTED) return [false];
      else if (isReadOnly) return [true];

      throw new Error('Error in useIfReadOnly hook while checking for CMSN role');
    }

    case ActivityDifferentiator.SEEN: {
      const isReadOnly =
        compareStatus(installationData.status, InstallationStatus.FOR_SEB_ACCEPTANCE) ===
        CompareStatusResult.AFTER;

      if (installationData.status === InstallationStatus.FOR_SEB_ACCEPTANCE)
        return [false];
      else if (isReadOnly) return [true];

      throw new Error('Error in useIfReadOnly hook while checking for SEEN role');
    }

    case ActivityDifferentiator.SPV: {
      const isEditMode =
        installationData.status === InstallationStatus.FOR_INSTALLATION_INSPECTION ||
        installationData.status === InstallationStatus.FOR_FINAL_INSPECTION ||
        installationData.status === InstallationStatus.SEB_ACCEPTED ||
        installationData.status === InstallationStatus.SEB_REJECTED;

      const isReadOnly =
        installationData.status === InstallationStatus.FOR_SEB_ACCEPTANCE ||
        compareStatus(installationData.status, InstallationStatus.SEB_REJECTED) ===
          CompareStatusResult.AFTER;

      if (isEditMode) return [false];
      else if (isReadOnly) return [true];

      throw new Error('Error in useIfReadOnly hook while checking for SPV role');
    }

    default:
      throw new Error('Error in useIfReadOnly hook');
  }
};
