import axios from 'axios';
import type { Attachment } from '../schemas';
import { API_TYPE, post } from './fetch';
import {
  generateIndexedDBKey,
  storedIndexedDBObjectType,
  upsertIndexedDBObject,
} from './indexedDB';

type UploadParams = {
  dbfiles: Attachment[];
  files: File[];
  networkNumber: string;
  questionSequenceNumber: number | string;
  questionSetIdParam: string | null;
  jwtToken: string;
};

type UploadDeviationsParams = {
  files: File[];
  networkNumber: string;
  jwtToken: string;
};

type DownloadParams = {
  files: Attachment[];
  jwtToken: string;
};

type SignatureResponse = {
  signedUrl: string;
  originalFilename: string;
  locationFilename: string;
};

export enum FileType {
  ATTACHMENT,
  DEVIATION,
}

export const getDownloadSignedUrlForAttachment = async (
  params: DownloadParams
): Promise<SignatureResponse[]> => {
  const { files, jwtToken } = params;
  const payload = {
    attachments: files.map(({ filename, location }) => ({ filename, location })),
  };
  //need to write code to convert tp blob andstpre in indexdb when downloaded image afterrefresh.. so that after immediate offline time we can show or pull image from indexdb
  return await post('v1/attachments/download', jwtToken, API_TYPE.APPLICATION, payload);
};

export const getDownloadSignedUrlForDeviationsAttachment = async (
  params: DownloadParams
): Promise<SignatureResponse[]> => {
  const { files, jwtToken } = params;
  const payload = {
    attachments: files.map(({ filename, location }) => ({ filename, location })),
  };
  return await post(
    'v1/deviations/files/downloads',
    jwtToken,
    API_TYPE.APPLICATION,
    payload
  );
};

export const getUploadSignedUrlForAttachment = async (
  params: UploadParams
): Promise<SignatureResponse[]> => {
  const { files, networkNumber, questionSequenceNumber, questionSetIdParam, jwtToken } =
    params;
  const payload = {
    filenames: files.map((file) => file.name),
  };
  return await post(
    `v1/attachments/${networkNumber}/${questionSetIdParam}/${Number(
      questionSequenceNumber
    )}/upload`,
    jwtToken,
    API_TYPE.APPLICATION,
    payload
  );
};

export const getUploadSignedUrlForDeviationsAttachment = async (
  params: UploadDeviationsParams
): Promise<SignatureResponse[]> => {
  const { files, networkNumber, jwtToken } = params;
  const payload = {
    filenames: files.map((file) => file.name),
    networkNumber,
  };
  return await post(
    'v1/deviations/files/uploads',
    jwtToken,
    API_TYPE.APPLICATION,
    payload
  );
};

export const uploadAttachments = async (
  params: UploadParams | UploadDeviationsParams,
  fileType = FileType.ATTACHMENT
): Promise<Attachment[] | undefined> => {
  const { dbfiles, files, networkNumber, questionSequenceNumber, questionSetIdParam } =
    params as UploadParams;
  const attachmentParam: Attachment[] = [];
  let uploadFiles: any = [];
  const base64IndexDbkey = generateIndexedDBKey(
    networkNumber,
    storedIndexedDBObjectType.BASE64SSTRING
  );

  try {
    const convertBlobToBase64 = (blob: any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = (reader.result as string).toString().split(',')[1];
          //ss3 bucket call amd resolve w

          resolve(base64data);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(blob);
      });
    };

    if (Array.isArray(files)) {
      const fileArray = files;
      const fileDataPromises = fileArray.map(async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = async () => {
            if (file instanceof File) {
              if (reader.result) {
                const blob = new Blob([reader.result], { type: MimeType.toString() });
                try {
                  const base64data = await convertBlobToBase64(blob);
                  resolve({
                    fileName: file.name,
                    base64Data: base64data,
                    mimeType: MimeType.toString(),
                    questionSequenceNumber: questionSequenceNumber,
                    questionSetIdParam: questionSetIdParam,
                  });
                } catch (error) {
                  reject(error);
                }
              }
            }
          };
          reader.readAsArrayBuffer(file); // Read file as ArrayBuffer
        });
      });
      Promise.all(fileDataPromises).then((filesData) => {
        uploadFiles = filesData;
      });
    }

    const responseSignedURLList =
      fileType === FileType.ATTACHMENT
        ? await getUploadSignedUrlForAttachment(params as UploadParams)
        : await getUploadSignedUrlForDeviationsAttachment(
            params as UploadDeviationsParams
          );
    if (navigator.onLine && responseSignedURLList && responseSignedURLList.length > 0) {
      await Promise.all(
        responseSignedURLList.map(async (response: SignatureResponse, index: number) => {
          attachmentParam.push({
            filename: response.originalFilename,
            location: response.locationFilename,
          });

          return axios.put(`${response.signedUrl}`, files[index]);
        })
      );
      dbfiles.forEach((dbfile: any) => {
        if (!uploadFiles.some((file: any) => file.fileName === dbfile.filename)) {
          uploadFiles.unshift(dbfile);
        }
      });
      await upsertIndexedDBObject(uploadFiles, base64IndexDbkey);
    } else {
      //if sync happens no need to have this else part as sync will handle offline case\
      if (uploadFiles) {
        await Promise.all(
          uploadFiles.map(async (fileData: any) => {
            attachmentParam.push({
              filename: fileData.fileName,
              location: `${networkNumber}/${questionSetIdParam}/${questionSequenceNumber}/${fileData.fileName}`,
            });
          })
        );
        dbfiles.forEach((dbfile: any) => {
          if (!uploadFiles.some((file: any) => file.fileName === dbfile.filename)) {
            uploadFiles.unshift(dbfile);
          }
        });
        await upsertIndexedDBObject(uploadFiles, base64IndexDbkey);
      }
    }
  } catch (e) {
    console.error('Failed to upload attachments', e);
  }
  return attachmentParam.length > 0 ? attachmentParam : undefined;
};
